import React from 'react'
import LazyLoad from 'react-lazyload'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import media from 'styles/media'

import useBreakpoint from 'hooks/useBreakpoint'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { LeftContentHero } from 'components/modules/LeftContentHero'
import { Container } from 'components/atoms/Container'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import MapContainer from 'components/atoms/MapContainer'
import { Home } from 'components/organisms/About/Home'
import { AboutUs } from 'components/organisms/About/AboutUs'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Contact = React.lazy(() => import('components/organisms/Contact'))
const Strengths = React.lazy(
  () => import('components/organisms/About/Strengths')
)
const OtherInvestments = React.lazy(
  () => import('components/organisms/About/OtherInvestments')
)
const BreadcrumbsContainer = styled(Container)`
  ${media.sm.max} {
    padding: 0px;
  }
`

const AboutPage: React.FC<PageProps<Queries.ONasPageQuery>> = ({ data }) => {
  const PAGE = data.about
  const PAGE_SEO = data.about?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const { md } = useBreakpoint()

  const HeroData = {
    lable: PAGE?.developer?.developerHeroTitle!,
    text: PAGE?.developer?.developerHeroSubtitle!,
    image: {
      src: PAGE?.developer?.developerHeroImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE?.developer?.developerHeroImg?.altText!,
    }!,
  }

  const HomeData = {
    heading: PAGE?.developer?.developerAurecTitle!,
    tiles: [
      {
        heading:
          PAGE?.developer?.developerAurecValues?.developerAurecValuesTitle!,
        description:
          PAGE?.developer?.developerAurecValues
            ?.developerAurecValuesDescription!,
        image: {
          src: PAGE?.developer?.developerAurecValues?.developerAurecValuesImg
            ?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.developer?.developerAurecValues?.developerAurecValuesImg
            ?.altText!,
        }!,
      },
      {
        heading:
          PAGE?.developer?.developerAurecHistory?.developerAurecHistoryTitle!,
        description:
          PAGE?.developer?.developerAurecHistory
            ?.developerAurecHistoryDescription!,
        image: {
          src: PAGE?.developer?.developerAurecHistory?.developerAurecHistoryImg
            ?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.developer?.developerAurecHistory?.developerAurecHistoryImg
            ?.altText!,
        }!,
      },
    ],
  }

  const AboutUsData = {
    heading: PAGE?.developer?.developerAboutUsTitle!,
    image: {
      src: PAGE?.developer?.developerAboutUsImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE?.developer?.developerAboutUsImg?.altText!,
    }!,
    context: {
      title:
        PAGE?.developer?.developerAboutUsAurecCapital
          ?.developerAboutUsAurecCapitalTitle!,
      subheading:
        PAGE?.developer?.developerAboutUsAurecCapital
          ?.developerAboutUsAurecCapitalSubtitle!,
      description:
        PAGE?.developer?.developerAboutUsAurecCapital
          ?.developerAboutUsAurecCapitalDescription!,
    }!,
  }

  const StrengthsData = {
    credibility: {
      title: 'Wiarygodność',
      heading:
        PAGE?.developer?.developerBenefitsCredibility
          ?.developerBenefitsCredibilityTitle!,
      description:
        PAGE?.developer?.developerBenefitsCredibility
          ?.developerBenefitsCredibilityDescription!,
      color:
        PAGE?.developer?.developerBenefitsCredibility
          ?.developerBenefitsCredibilityColor!,
    }!,
    development: {
      title: 'Zrównoważony rozwój',
      heading:
        PAGE?.developer?.developerBenefitsDevelopment
          ?.developerBenefitsDevelopmentTitle!,
      description:
        PAGE?.developer?.developerBenefitsDevelopment
          ?.developerBenefitsDevelopmentDescription!,
      color:
        PAGE?.developer?.developerBenefitsDevelopment
          ?.developerBenefitsDevelopmentColor!,
    }!,
    reachanility: {
      title: 'Osiągalność',
      heading:
        PAGE?.developer?.developerBenefitsReachability
          ?.developerBenefitsReachabilityTitle!,
      description:
        PAGE?.developer?.developerBenefitsReachability
          ?.developerBenefitsReachabilityDescription!,
      color:
        PAGE?.developer?.developerBenefitsReachability
          ?.developerBenefitsReachabilityColor!,
    }!,
  }

  const OtherInvestmentsData = {
    heading: PAGE?.developer?.developerInvestmentsTitle!,
    tiles: PAGE?.developer?.developerInvestmentsSingle?.map((item) => ({
      heading: item?.developerInvestmentsSingleName!,
      description: item?.developerInvestmentsSingleDescription!,
      image: {
        src: item?.developerInvestmentsSingleImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: item?.developerInvestmentsSingleImg?.altText!,
      },
    }))!,
  }

  const ContactData = {
    heading: PAGE?.developer?.developerFormsTitle!,
    formTitle: PAGE?.developer?.developerFormsFormsTitle!,
    image: {
      src: PAGE?.developer?.developerFormsImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE?.developer?.developerFormsImg?.altText!,
    }!,
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <MapContainer size="big" position={['bottom', 'right']}>
        <LeftContentHero
          lable={HeroData.lable}
          text={HeroData.text}
          image={HeroData.image}
          textTag="h4"
          headingTag="h1"
        />
      </MapContainer>
      <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 30]}>
        <Breadcrumbs crumbs={[{ name: 'O nas' }]} />
      </BreadcrumbsContainer>
      <MapContainer size="big" position={['bottom', 'left']}>
        <Home heading={HomeData.heading} tiles={HomeData.tiles} />
      </MapContainer>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <AboutUs
            heading={AboutUsData.heading}
            image={AboutUsData.image}
            context={AboutUsData.context}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Strengths
            credibility={StrengthsData.credibility}
            development={StrengthsData.development}
            reachanility={StrengthsData.reachanility}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <OtherInvestments
            heading={OtherInvestmentsData.heading}
            tiles={OtherInvestmentsData.tiles}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Contact
            heading={ContactData.heading}
            formTitle={ContactData.formTitle}
            image={ContactData.image}
          />
        </React.Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ONasPage {
    about: wpPage(slug: { regex: "/deweloper/" }) {
      developer {
        developerHeroTitle
        developerHeroSubtitle
        developerHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        developerAurecTitle
        developerAurecValues {
          developerAurecValuesTitle
          developerAurecValuesDescription
          developerAurecValuesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        developerAurecHistory {
          developerAurecHistoryTitle
          developerAurecHistoryDescription
          developerAurecHistoryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        developerAboutUsTitle
        developerAboutUsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
        developerAboutUsAurecCapital {
          developerAboutUsAurecCapitalTitle
          developerAboutUsAurecCapitalSubtitle
          developerAboutUsAurecCapitalDescription
        }

        developerBenefitsCredibility {
          developerBenefitsCredibilityTitle
          developerBenefitsCredibilityDescription
          developerBenefitsCredibilityColor
        }
        developerBenefitsDevelopment {
          developerBenefitsDevelopmentTitle
          developerBenefitsDevelopmentDescription
          developerBenefitsDevelopmentColor
        }
        developerBenefitsReachability {
          developerBenefitsReachabilityTitle
          developerBenefitsReachabilityDescription
          developerBenefitsReachabilityColor
        }
        developerInvestmentsTitle
        developerInvestmentsSingle {
          developerInvestmentsSingleName
          developerInvestmentsSingleDescription
          developerInvestmentsSingleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
        developerFormsTitle
        developerFormsFormsTitle
        developerFormsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`
