import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'

export const HomeConatiner = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.md.max} {
    gap: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
  ${media.sm.max} {
    padding: 48px 24px;
  }
`

export const HomeContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;

  ${media.lg.max} {
    flex-direction: column;
  }
  ${media.md.max} {
    gap: 32px;
  }
  ${media.sm.max} {
    flex-direction: column;
    margin: 0px auto;
  }
`

export const StyledH1Light = styled(H1Light)`
  ${media.sm.max} {
    width: 272px;
    margin: 0px auto;
    text-align: left;
  }
`

export const ImageWraper = styled.div`
  position: relative;
  width: 100%;
  max-width: 448px;
  min-width: 272px;

  aspect-ratio: 448 / 560;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: -16px;
    width: 86px;
    height: 87px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 12px;

    ${media.sm.max} {
      width: 39px;
      height: 39px;

      bottom: -8px;
      left: -8px;

      border-radius: 5px;
    }
  }

  ${media.lg.max} {
    margin: 0px auto;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 448px;
  min-width: 272px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;

  ${media.lg.max} {
    max-width: 580px;

    margin: 0px auto;
    gap: 24px;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ContentBox = styled.div`
  width: calc(100% + 132px);
  padding: 32px 40px;
  margin-bottom: 70px;

  position: relative;
  right: 132px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);

  &:after {
    content: '';
    width: 24px;
    height: 24px;

    position: absolute;
    top: -12px;
    left: -12px;

    background: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;

    ${media.sm.max} {
      display: none;
    }
  }

  ${media.lg.max} {
    width: 100%;

    right: 0px;
    margin: 0px auto;
  }
  ${media.sm.max} {
    padding: 24px;
  }
`
