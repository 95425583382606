import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'

export const HomeConatiner = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.md.max} {
    gap: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
  ${media.sm.max} {
    padding: 48px 24px;
  }
`

export const ImageWraper = styled.div`
  width: 100%;
  height: fit-content;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${media.sm.max} {
    max-height: 280px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 503px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.sm.max} {
    max-width: 272px;
  }
`

export const HomeContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;

  & ${ContentContainer}:nth-child(1) ${ImageWraper} {
    max-height: 280px;
  }
  & ${ContentContainer}:nth-child(2) ${ImageWraper} {
    max-height: 480px;
    ${media.md.max} {
      max-height: 280px;
    }
  }

  ${media.md.max} {
    gap: 32px;
  }
  ${media.sm.max} {
    flex-direction: column;
    margin: 0px auto;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Ball = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};

  ${media.sm.max} {
    width: 12px;
    height: 12px;
  }
`

export const StyledH1Light = styled(H1Light)`
  ${media.sm.max} {
    width: 272px;
    margin: 0px auto;
    text-align: left;
  }
`
