import React from 'react'

import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'
import { H3Bold, TextBody1 } from 'components/atoms/Typography'
import {
  Ball,
  ContentContainer,
  HomeConatiner,
  HomeContent,
  ImageWraper,
  StyledH1Light,
  TextContent,
} from './Home.styles'

type TileData = {
  heading: string
  description: string
  image: ImageType
}

type HomeProps = {
  heading: string
  tiles: TileData[]
}

export const Home: React.FC<HomeProps> = ({ heading, tiles }) => {
  return (
    <HomeConatiner padding={[72, 50]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <HomeContent>
        <ContentContainer>
          <ImageWraper>
            <LazyImage src={tiles[0].image.src} alt={tiles[0].image.alt!} />
          </ImageWraper>
          <TextContent>
            <H3Bold dangerouslySetInnerHTML={{ __html: tiles[0].heading }} />
            <Ball />
            <TextBody1
              dangerouslySetInnerHTML={{ __html: tiles[0].description }}
            />
          </TextContent>
        </ContentContainer>

        <ContentContainer>
          <ImageWraper>
            <LazyImage src={tiles[1].image.src} alt={tiles[1].image.alt!} />
          </ImageWraper>
          <TextContent>
            <H3Bold dangerouslySetInnerHTML={{ __html: tiles[1].heading }} />
            <Ball />
            <TextBody1
              dangerouslySetInnerHTML={{ __html: tiles[1].description }}
            />
          </TextContent>
        </ContentContainer>
      </HomeContent>
    </HomeConatiner>
  )
}
