import React from 'react'

import { ImageType } from 'types/image'

import { ReactComponent as AurecLogo } from 'assets/images/aurecLogo.svg'

import { LazyImage } from 'components/atoms/Image'
import { H2Light, H3Bold, TextBody1 } from 'components/atoms/Typography'
import {
  Content,
  ContentBox,
  HomeConatiner,
  HomeContent,
  ImageWraper,
  StyledH1Light,
  TextContent,
} from './AboutUs.styles'

type ContextType = {
  title: string
  subheading: string
  description: string
}

type AboutUsProps = {
  heading: string
  context: ContextType
  image: ImageType
}

export const AboutUs: React.FC<AboutUsProps> = ({
  heading,
  context,
  image,
}) => {
  return (
    <HomeConatiner padding={[72, 50]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <HomeContent>
        <ImageWraper>
          <LazyImage src={image.src} alt={image.alt!} objectFit="cover" />
        </ImageWraper>
        <Content>
          <TextContent>
            <H2Light
              as="h3"
              dangerouslySetInnerHTML={{ __html: context.subheading }}
            />
            <AurecLogo />
          </TextContent>

          <ContentBox>
            <H3Bold dangerouslySetInnerHTML={{ __html: context.title }} />
            <TextBody1
              dangerouslySetInnerHTML={{ __html: context.description }}
            />
          </ContentBox>
        </Content>
      </HomeContent>
    </HomeConatiner>
  )
}
